import React, { useState } from "react";
import { Button, Image, Modal, Tooltip } from "antd";
import {
  ExportOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import IconReaction from "./icons/reaction";
import IconPeople from "./icons/people";
import IconVisibility from "./icons/visibility";
import IconFavorite from "./icons/favorite";
import IconComment from "./icons/comment";
import IconReply from "./icons/reply";
import { FeedPostModel } from "../api/domain/models/feed-post-model";
import { Link, useNavigate } from "react-router-dom";
import NetworkIcon from "../scripts/network-icon";
import LoadedImage from "./LoadedImage";
import { useAppDispatch } from "../redux/store/hooks";
import { addStopSource, changePostTrust } from "../redux/actions";
import { useSelector } from "react-redux";
import { getReferences } from "../redux/selectors";

type Props = {
  post: FeedPostModel;
  type?: "topic";
  changeTonal: (post: any) => void;
};

const Post: any = ({ post, changeTonal }: Props) => {
  const [avatarNotLoaded, setAvatarNotLoaded] = useState(false);
  const references = useSelector(getReferences);
  const [deletedSource, setDeletedSource] = useState(false);
  const dispatch = useAppDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const shortImages = post?.media?.images;
  const [tonal, setTonal] = useState(post.trust);
  const [loading, setLoading] = useState(false);
  const [showFullText, setShowFullText] = useState(false);

  const getTitle = (title: any, id: any) => {
    if (id == 4 && title?.toUpperCase().slice(0, 11) == "КОММЕРСАНТЪ")
      return title.slice(0, 11);
    else return title;
  };

  const getPostText = (): { __html: string } => {
    return { __html: post.text };
  };

  const removeSource = (ownerId: string, networkId: string, ref_id: number) => {
    dispatch(addStopSource(ownerId, networkId, references[0]));
    setDeletedSource(true);
  };

  const handleOk = (): void => {
    setLoading(true);
    dispatch(
      changePostTrust(
        post.id,
        post.ownerId,
        post.network.id,
        post.isManual,
        tonal
      )
    );
    setLoading(false);
    setIsModalVisible(false);
    changeTonal(tonal);
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  return (
    <div className={`post-flex gray-block grid4-1`}>
      {/*post && shortImages[0] && !imgNotLoaded ? (
        <Image
          src={shortImages[0]?.url}
          fallback={shortImages[0]?.url}
          onError={() => setImgNotLoaded(true)}
        />
      ) : (
        <>
          <Image src={"/not-image.png"} onError={() => setImgNotLoaded(true)}/>
        </>
      )*/}
      <div className="flex">
        <div>
          {post && shortImages[0] && (
            <div id={post.id + post.ownerId} className="post-img">
              {Object.keys(shortImages).length > 1 && (
                <div className="white-index">
                  {"    " + "+" + " " + (Object.keys(shortImages).length - 1)}
                </div>
              )}
              {post.media.images.map((item: any, i: any) => (
                <LoadedImage item={item} key={i} />
              ))}
            </div>
          )}
        </div>

        <div className="post__wrap">
          <div>
            <div className="flex-base">
              <div className="flex-start">
                {
                  <div className="post-source">
                    <a href={post?.author.url} target="_blank" rel="noreferrer">
                      {avatarNotLoaded ? (
                        <img
                          src="/Avatar.png"
                          className="logo-icon"
                          alt=""
                          onError={() => setAvatarNotLoaded(true)}
                        />
                      ) : (
                        <img
                          src={post?.author.logo}
                          className="logo-icon"
                          alt=""
                          onError={() => setAvatarNotLoaded(true)}
                        />
                      )}
                      <div className="network-icon">
                        <NetworkIcon networkName={post?.network.name} />
                      </div>
                    </a>
                  </div>
                }
                <div className="post-title__info ">
                  <a
                    href={post?.author.url}
                    target="_blank"
                    className="post-title__source block-title"
                    rel="noreferrer"
                  >
                    {getTitle(post?.author.name, post?.network.id)}
                  </a>
                  <div
                    className="link-text"
                    onClick={() =>
                      removeSource(post.ownerId, post.network.id, references[0])
                    }
                  >
                    {deletedSource
                      ? "Источник будет исключен из выдачи"
                      : "Исключить источник"}
                  </div>

                  <div className="author__info flex">
                    {post?.createdDate.substr(0, 8)},{" "}
                    {post?.createdDate.substr(8, 9)}
                    <Tooltip color={"#7856FE"} title="Cсылка на публикацию">
                      <a
                        href={post?.uri}
                        target="_blank"
                        className="post-title__source"
                        rel="noreferrer"
                      >
                        <ExportOutlined />
                      </a>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="post-statistics">
                {post?.network.id !== "4" && (
                  <>
                    {post?.statistics.viewed != "0" && (
                      <div className="post-statistics__item flex-simple">
                        <IconReaction />
                        <span>
                          {Math.ceil(
                            (+post?.statistics.likes /
                              +post?.statistics.viewed) *
                              100
                          ) + "%"}
                        </span>
                      </div>
                    )}
                    <div className="post-statistics__item flex-simple">
                      <IconFavorite />
                      <span>{post?.statistics.likes}</span>
                    </div>
                    <div className="post-statistics__item flex-simple">
                      <IconReply />
                      <span>{post?.statistics.reposts}</span>
                    </div>
                    <div className="post-statistics__item flex-simple">
                      <IconComment />
                      <span>{post?.statistics.comments}</span>
                    </div>
                    <div className="post-statistics__item flex-simple">
                      <IconVisibility />
                      <span>{post?.statistics.viewed}</span>
                    </div>
                    <div className="post-statistics__item flex-simple">
                      <IconPeople />
                      <span>{post?.attendance}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="post__main">
            {post?.network.id === "4" && (
              <div className="post-content__title">{post?.title}</div>
            )}
            <div className="text-container">
              <div
                className={`post-content${
                  post.text.length > 450 ? "__text" : " short-text"
                }${showFullText ? "-full" : ""}`}
                dangerouslySetInnerHTML={getPostText()}
              />
            </div>
            {post.text.length > 450 && (
              <div
                className="link-text"
                onClick={() => setShowFullText((prevState) => !prevState)}
              >
                {showFullText ? "Свернуть" : "Читать далее"}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="grid-base">
        <div
          className={`${
            post?.trust === -1
              ? "red-trust"
              : post?.trust === 1
              ? "green-trust"
              : "white-trust"
          }`}
          key={post?.id}
          onClick={() => setIsModalVisible(true)}
        >
          {post?.trust === -1
            ? "Негативный"
            : post?.trust === 1
            ? "Позитивный"
            : post?.trust === 0
            ? "Нейтральный"
            : ""}
        </div>
        <Button>
          <Link
            to={`/post/${post?.id}/${post?.ownerId}/${post?.network?.id}/${references[0]}`}
            target="_blank"
          >
            Подробнее
            <RightOutlined />
          </Link>
        </Button>
      </div>
      <Modal
        title="Тональность"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Отменить"
        okText="Изменить"
        className="change-post-tonal-modal__parent"
      >
        <div className="change-post-tonal-modal">
          {loading ? (
            <div className="feed-posts-loader">
              <LoadingOutlined />
            </div>
          ) : (
            <div className="change-post-tonal-modal__wrap">
              <div className="change-post-tonal-modal__list">
                <div
                  className={
                    tonal == 1
                      ? "green-active green flex-center"
                      : "green-bg green flex-center"
                  }
                  onClick={() => setTonal(1)}
                >
                  Положительная
                </div>
                <div
                  className={
                    tonal == 0
                      ? "gray-active grey flex-center"
                      : "gray-block grey flex-center"
                  }
                  onClick={() => setTonal(0)}
                >
                  Нейтральная
                </div>
                <div
                  className={
                    tonal == -1
                      ? "red-active red flex-center"
                      : "red-bg red flex-center"
                  }
                  onClick={() => setTonal(-1)}
                >
                  Негативная
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Post;
