import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  DatePicker,
  Drawer,
  Radio,
  RadioChangeEvent,
} from "antd";
import React, { useEffect, useState } from "react";
import locale from "antd/es/date-picker/locale/ru_RU";
import { useSelector } from "react-redux";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import { summaryService } from "../api";
import { getProduct, getThread } from "../redux/selectors";

const BestSources: React.FC = () => {
  const [value, setValue] = useState("today");
  const { RangePicker } = DatePicker;
  const feedFilter = useSelector(getFeedFilter);
  const options = [
    { label: " Другое", value: "period" },
    { label: "Сегодня", value: "today" },
    { label: "7д", value: "week" },
    { label: "1м", value: "month" },
  ];
  const thread = useSelector(getThread);
  const product = useSelector(getProduct);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const [keys, setKeys] = useState([]);
  const [total, setTotal] = useState(1);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (thread) {
      let params = {
        thread_id: +thread.id,
        product_id: product,
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        type: "social",
        limit: 4,
      };
      setLoading(true);
      summaryService
        .loadSourceTop(params)
        .then((resp) => {
          setKeys(resp);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [thread, dateString, product]);

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
  };

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setValue(value);
    if (value == "today")
      setDateString([
        new Date().toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    if (value == "week") {
      const start = new Date();
      start.setDate(start.getDate() - 6);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
    if (value == "month") {
      const start = new Date();
      start.setDate(start.getDate() - 29);
      setDateString([
        start.toJSON().split("T")[0],
        new Date().toJSON().split("T")[0],
      ]);
    }
  };

  return (
    <div className="white-block">
      <div className="flex-space margin-bottom">
        <div>
          <p className="block-title">
            Лучшие источники{" "}
            <InfoCircleOutlined className="info" onClick={showDrawer} />
          </p>
          <div className="flex">
            <ConfigProvider
              theme={{
                components: {
                  Radio: {
                    buttonSolidCheckedColor: "#7856FE",
                    buttonSolidCheckedBg: "rgba(0, 0, 0, 0.1)",
                    colorPrimary: "rgba(0, 0, 0, 0.1)",
                    buttonSolidCheckedHoverBg: "rgba(0, 0, 0, 0.2)",
                  },
                },
              }}
            >
              <Radio.Group
                options={options}
                onChange={onChange}
                value={value}
                optionType="button"
                buttonStyle="solid"
              />
            </ConfigProvider>

            {value == "period" && (
              <RangePicker
                format={"DD-MM-YYYY"}
                onChange={onPeriodChange}
                style={{ width: "278px" }}
                className="choose-period"
                allowClear={false}
                locale={locale}
              />
            )}
          </div>
        </div>
      </div>
      {loading ? (
        <div className="feed-posts-loader">
          <LoadingOutlined />
        </div>
      ) : (
        <div className="grid4">
          {keys?.map((item: any, id) => (
            <div className="grid-block gray-block" key={id}>
              <div className="margin-bottom">
                <a href={item?.url} className="flex align-block">
                  {item?.key != "gs" && (
                    <img
                      //src={"/" + item?.key + ".png"}
                      src={item?.logo}
                      alt=""
                      className="source-icon"
                    />
                  )}
                  {/*findSource(item?.key)*/}
                  <div className="block-title top-post__text">{item.title}</div>
                </a>
              </div>
              <div className="margin-bottom">
                <div className="title-small">
                  {item?.media_presence?.toFixed(2)}%
                </div>
              </div>
              {/*
                    <div className="margin-bottom">
                      <p className="grey">
                        ERR{" "}
                        <InfoCircleOutlined
                          className="info"
                          onClick={showDrawer}
                        />
                      </p>
                      <div className="title-small">0.55</div>
                    </div>
                      */}
              <div className="margin-bottom">
                <p className="grey">Просмотров</p>
                <div className="title-middle">
                  {item?.viewed.toLocaleString()}{" "}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <Drawer title="Что отображает эта таблица?" onClose={onClose} open={open}>
        <p className="purple-border">
          В таблице представлены ТОП-4 источника за период с наиболее высокими
          показателями ERR и количеством просмотров.
        </p>
        <p className="title-small">Как интерпретировать данные</p>
        <ol>
          <li>
            Временные интервалы: Вы можете выбрать различные временные интервалы
            для анализа динамики.
          </li>
        </ol>
        <p className="title-small">Источники данных</p>
        <ul>
          <li>Telegram</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>Instagram</li>
          <li>Вконтакте</li>
          <li>Одноклассники</li>
          <li>Дзен</li>
        </ul>
        <p className="gray-block">
          <p className="gray-title">ПРИМЕЧАНИЕ</p>Данные обновляются в реальном
          времени, что позволяет вам следить за актуальными трендами и реакцией
          аудитории на ваш контент
        </p>
      </Drawer>
    </div>
  );
};

export default BestSources;
