import {
  Button,
  DatePicker,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  changePostPage,
  feedFilterClear,
  feedFilterSubmit,
} from "../redux/actions";
import { getReferencesFilter, getThread } from "../redux/selectors";
import { useAppDispatch } from "../redux/store/hooks";
import locale from "antd/es/date-picker/locale/ru_RU";
import { getFeedFilter } from "../redux/selectors/feed-filter.selectors";
import IconFileExcel from "../components/icons/fileExcel";
import IconFileWord from "../components/icons/fileWord";
import { postService } from "../api";

const Reports = () => {
  const [value, setValue] = useState("today");
  const [loading, setLoading] = useState(false);
  const { RangePicker } = DatePicker;
  const feedFilter = useSelector(getFeedFilter);
  const [tonality, setTonality] = useState(feedFilter.tonal);
  const [repostOption, setRepostOption] = useState(feedFilter.repostoption);
  const [friendly, setFriendly] = useState(feedFilter.friendly);
  const [sorting, setSorting] = useState(feedFilter.sort);
  const [switchValue, setSwitchValue] = useState(false);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalPostOpen, setModalPostOpen] = useState(false);
  const [dateString, setDateString] = useState([
    feedFilter.from,
    feedFilter.to,
  ]);
  const referencesFilter = useSelector(getReferencesFilter);

  const [source, setSource] = useState({
    smi: feedFilter.smi,
    vk: feedFilter.vk,
    tw: feedFilter.tw,
    ig: feedFilter.ig,
    tg: feedFilter.tg,
    yt: feedFilter.yt,
    dz: feedFilter.dz,
    ok: feedFilter.ok,
    fb: feedFilter.fb,
  });
  const [sourceOptions, setSourceOptions] = useState([
    "tg",
    "vk",
    "ok",
    "fb",
    "tw",
    "yt",
    "dz",
    "ig",
    "smi",
  ]);
  const thread = useSelector(getThread);
  const dispatch = useAppDispatch();

  useEffect(() => {
    onClear();
  }, []);

  useEffect(() => {
    dispatch(
      feedFilterSubmit({
        from: dateString[0].slice(0, 10) + " 00:00:00",
        to: dateString[1].slice(0, 10) + " 23:59:59",
        smi: source.smi,
        vk: source.vk,
        tw: source.tw,
        fb: source.fb,
        ig: source.ig,
        tg: source.tg,
        yt: source.yt,
        dz: source.dz,
        ok: source.ok,
        tonal: tonality,
        friendly: friendly,
        sorting: sorting,
        profile_id: [],
        repostoption: repostOption,
      })
    );
  }, [source, dateString, sorting, tonality, thread, repostOption]);

  let order = "desc";
  let type = "date";
  if (sorting == "reitAsc") {
    type = "rating";
    order = "asc";
  }
  if (sorting == "reitDesc") {
    type = "rating";
    order = "desc";
  }
  if (sorting == "dateAsc") {
    type = "date";
    order = "asc";
  }
  if (sorting == "dateDesc") {
    type = "date";
    order = "desc";
  }
  if (sorting == "likeAsc") {
    type = "likes";
    order = "asc";
  }
  if (sorting == "likeDesc") {
    type = "likes";
    order = "desc";
  }
  if (sorting == "repostAsc") {
    type = "reposts";
    order = "asc";
  }
  if (sorting == "repostDesc") {
    type = "reposts";
    order = "desc";
  }
  if (sorting == "viewAsc") {
    type = "viewed";
    order = "asc";
  }
  if (sorting == "viewDesc") {
    type = "viewed";
    order = "desc";
  }
  if (sorting == "comAsc") {
    type = "commentaries";
    order = "asc";
  }
  if (sorting == "comDesc") {
    type = "commentaries";
    order = "desc";
  }
  if (sorting == "") {
    type = "date";
    order = "desc";
  }

  let sourceNums = sourceOptions.map((i) => {
    switch (i) {
      case "vk":
        return 1;
        break;
      case "dz":
        return 11;
        break;
      case "ok":
        return 10;
        break;
      case "smi":
        return 4;
        break;
      case "tg":
        return 5;
        break;
      case "ig":
        return 7;
        break;
      case "tw":
        return 2;
        break;
      case "fb":
        return 3;
        break;
      case "yt":
        return 8;
        break;
      default:
        return 0;
    }
  });

  let fullFilterString = `thread_id=${thread?.id}&from=${
    dateString[0].slice(0, 10) + " 00:00:00"
  }&to=${
    dateString[1].slice(0, 10) + " 23:59:59"
  }&sort[type]=${type}&sort[order]=${order}&filter[trustoption]=${
    feedFilter.tonal
  }&filter[repostoption]=${feedFilter.repostoption}${sourceNums
    .map((item, id) => `&filter[network_id][${id}]=${item}`)
    .join("")}`;

  const onClear = (): void => {
    setDateString([
      new Date().toJSON().split("T")[0],
      new Date().toJSON().split("T")[0],
    ]);
    setSource({
      smi: true,
      vk: true,
      tw: true,
      fb: true,
      ig: true,
      tg: true,
      dz: true,
      yt: true,
      ok: true,
    });
    setValue("today");
    setSourceOptions(["tg", "vk", "ok", "fb", "tw", "yt", "dz", "ig", "smi"]);
    setTonality(null);
    setFriendly("");
    setSorting(null);
    setRepostOption("whatever");
    setSwitchValue(false);
    dispatch(feedFilterClear());
    dispatch(changePostPage(1));
  };

  const onPeriodChange = (value: any, dateString: string[]): void => {
    setDateString(dateString);
    dispatch(changePostPage(1));
  };

  const convert = (resp: any) => {
    let base64String = resp.content;
    const base64Data = base64String.replace(/^data:.+;base64,/, "");
    const byteCharacters = atob(base64Data); // Decode Base64 string
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: resp.mime });
    const url = URL.createObjectURL(blob);

    // Create a link element to download the file
    const link = document.createElement("a");
    link.href = url;
    link.download = resp.filename;
    link.click();

    // Cleanup
    URL.revokeObjectURL(url);
  };

  const getReport = (period?: string) => {
    setLoading(true);
    let params = period
      ? `?thread_id=${thread?.id}&period=${period}&reference_ids[]=${referencesFilter}&ajax=1`
      : `?thread_id=${thread?.id}&reference_ids[]=${referencesFilter}&from=${
          dateString[0].slice(0, 10) + " 00:00:00"
        }&to=${dateString[1].slice(0, 10) + " 23:59:59"}&ajax=1`;
    postService
      .loadReport(params)
      .then((resp) => {
        convert(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getPostsReport = () => {
    setLoading(true);
    let params = `?${fullFilterString}&filter[referenceFilter][0]=${referencesFilter}&ajax=1`;
    postService
      .loadPostsReport(params)
      .then((resp) => {
        convert(resp);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="App">
      <h1>Отчеты</h1>
      <div className="white-block">
        <div className="feed-posts">
          <div className="flex">
            <div className="grey">Всего 5 отчетов</div>
          </div>
          <div className="grid6">
            <div
              className="gray-block"
              onClick={() => {
                getReport("day");
              }}
            >
              <div className="inline-grid">
                <IconFileWord />
                <span>Ежедневный отчет</span>
              </div>
            </div>
            <div
              className="gray-block"
              onClick={() => {
                getReport("week");
              }}
            >
              <div className="inline-grid">
                <IconFileWord />
                <span>Еженедельный отчет</span>
              </div>
            </div>
            <div
              className="gray-block"
              onClick={() => {
                getReport("month");
              }}
            >
              <div className="inline-grid">
                <IconFileWord />
                <span>Ежемесячный отчет</span>
              </div>
            </div>
            <div
              className="gray-block btn-cursor"
              onClick={() => setModalOpen(true)}
            >
              <div className="inline-grid">
                <IconFileWord />
                <span>Отчет за период</span>
              </div>
            </div>
            <div className="gray-block" onClick={() => setModalPostOpen(true)}>
              <div className="inline-grid">
                <IconFileExcel />
                <span>Сводка постов</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={"Формирование отчета"}
        open={reportModalOpen || loading}
        onCancel={() => setReportModalOpen(false)}
        footer={false}
        maskClosable={false}
      >
        <div>
          Идёт формирование отчёта. Пожалуйста, не закрывайте страницу,
          дождитесь завершения загрузки. Это может занять несколько минут. Отчёт
          сохранится в папку "Загрузки" на вашем компьютере.
        </div>
      </Modal>
      <Modal
        title={"Отчет за период"}
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalOpen(false)}>
            Отмена
          </Button>,
          <Button
            key="link"
            type="primary"
            onClick={() => {
              setModalOpen(false);
              //setReportModalOpen(true);
              getReport();
            }}
            // onClick={handleOk}
          >
            Загрузить
          </Button>,
        ]}
        maskClosable={false}
      >
        <RangePicker
          format={"DD-MM-YYYY"}
          onChange={onPeriodChange}
          style={{ width: "278px" }}
          allowClear={false}
          locale={locale}
        />
      </Modal>
      <Modal
        title={"Сводка постов за период"}
        open={modalPostOpen}
        onCancel={() => setModalPostOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalPostOpen(false)}>
            Отмена
          </Button>,
          <Button
            key="link"
            //href={postListReport}
            type="primary"
            onClick={() => {
              setModalPostOpen(false);
              //setReportModalOpen(true);
              getPostsReport();
            }}
            // onClick={handleOk}
          >
            Загрузить
          </Button>,
        ]}
        maskClosable={false}
      >
        <RangePicker
          format={"DD-MM-YYYY"}
          onChange={onPeriodChange}
          style={{ width: "278px" }}
          allowClear={false}
          locale={locale}
        />
      </Modal>
    </div>
  );
};

export default Reports;
