import React from "react";

const IconFileExcel: React.FC = () => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.07715 7.99544V4.79411C6.07715 3.02606 7.51043 1.59277 9.27848 1.59277H25.2851C27.0532 1.59277 28.4865 3.02606 28.4865 4.79411V27.2034C28.4865 28.9715 27.0532 30.4048 25.2851 30.4048H9.27848C7.51043 30.4048 6.07715 28.9715 6.07715 27.2034V24.0021"
        stroke="#32C481"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1.2749"
        y="7.99609"
        width="16.0067"
        height="16.0067"
        rx="3.2"
        stroke="#32C481"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4862 11.3257H17.2815"
        stroke="#32C481"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4862 20.8013H17.2815"
        stroke="#32C481"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2818 1.59277V30.4048"
        stroke="#32C481"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7864 18.7996L6.98438 13.1973"
        stroke="#32C481"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.98438 18.7996L11.7864 13.1973"
        stroke="#32C481"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconFileExcel;
