
import * as actionType from './types'
import { resetUser, setUser } from './user.actions'
import { removeFeedFilter } from './feed-filter.actions'
import { removeSelectedReferences } from './selected-references.actions'
import LogRocket from 'logrocket'
import { AnyAction } from 'redux'
import { setAppIsInitialize } from './app.actions'
import { authService } from '../../api'
import { useNavigate } from 'react-router-dom'

export const setIsLoggedIn = (value:any): AnyAction => ({ type: actionType.AUTH_SET_IS_LOGGED_IN, payload: value })

export const loginAuth = (user:any) => async (dispatch:any) => {
  authService.getToken(user)
  .then(resp => {
    localStorage.setItem("access", resp.token);
  })
  .catch(error => {
    console.log('error', error)
    dispatch({ type: actionType.LOGIN_FAIL, payload: error.message })
  })
  setTimeout(()=>
    authService.auth(user)
      .then(resp => {
        LogRocket.identify(user.login)
        dispatch(setUser({
          name: resp.username,
          group: resp.group,
          email: user.login,
          uid: resp.uid
        }))
        dispatch({ type: actionType.LOGIN_SUCCESS })
        //window.location.replace('/main')
        dispatch(setAppIsInitialize(true))
      })
      .catch(error => {
        console.log('error', error)
        dispatch({ type: actionType.LOGIN_FAIL, payload: error.message })
      }), 1000)
}

export const logoutAuth = () => async (dispatch:any) => {
  dispatch({ type: actionType.LOGOUT_START })
  authService.logout()
    .then(() => {
      dispatch({ type: actionType.LOGOUT_SUCCESS })
    })
    .catch(error => {
      console.log('error', error)
      dispatch({ type: actionType.LOGOUT_FAIL })
    })
    .finally(() => {
      dispatch(resetUser())
      dispatch(removeFeedFilter())
      dispatch(removeSelectedReferences())
      window.location.replace('/')
    })
}
